import React from "react";
import { Row } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import {
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import ReactGA from 'react-ga';
import '../views/styles.scss';

const sharingText = `Please donate to the Earth Saver campaign, for koala 🐨 and wildlife habitat restoration after the fires! Here is the link to donate https://earthsaver.io`;

function ShareBatch({ center=true, white }) {

  const trackSharing = label => ReactGA.event({ category: 'Social Sharing', action: 'Opent sharebox', label });

  return (
    <div>

    <Row className={`${center ? "justify-content-center" : ""} mt-2`}>
      <TwitterShareButton
        onClick={() => trackSharing('Twitter')}
        style={{ paddingLeft: 3, paddingRight: 3 }}
        url="https://earthsaver.io" title={sharingText}
        hashtags={["earthsaver", "restore"]}
      >
        <TwitterIcon size={32} round={true} />
      </TwitterShareButton>

      <WhatsappShareButton
        onClick={() => trackSharing('Whatsapp')}
        style={{ paddingLeft: 3, paddingRight: 3 }}
        url="https://earthsaver.io" title={sharingText} separator={' '}
      >
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>

      <FacebookShareButton
        onClick={() => trackSharing('Facebook')}
        style={{ paddingLeft: 3, paddingRight: 3 }} hashtag="earthsaver"
        url="https://earthsaver.io" quote={sharingText}
      >
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>

      <TelegramShareButton
        onClick={() => trackSharing('Telegram')}
        style={{ paddingLeft: 3, paddingRight: 3 }}
        url="https://earthsaver.io" title={sharingText}
      >
        <TelegramIcon size={32} round={true} />
      </TelegramShareButton>

      <LinkedinShareButton
        onClick={() => trackSharing('LinkedIn')}
        style={{ paddingLeft: 3, paddingRight: 3 }}
        url="https://earthsaver.io" title={sharingText}
        source="https://earthsaver.io"
      >
        <LinkedinIcon size={32} round={true} />
      </LinkedinShareButton>

    </Row>
    <p className={`mt-1 text-center ${white ? 'white' : ''}`}>
      Share your support for all the Earth Savers
    </p>
    </div>
  );
}

export default ShareBatch;


