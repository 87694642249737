import validator from 'validator';

export function isEmail(email) {
  return validator.isEmail(email + '');
}

export function isAlpha(text) {
  return validator.isAlpha(text + '');
}

export function isCreditCard(creditCard) {
  const sanitized = creditCard.replace(/ /g, '');
  return validator.isCreditCard(sanitized);
}

export function isValidExpiration(expiry) {
  const sanitized = expiry.replace('/', '').replace(/ /g, '');
  return validator.isNumeric(sanitized);
}

export function isValidCvc(cvc) {
  const isValid = validator.isNumeric(cvc + '') && (cvc + '').length >= 3;
  return isValid;
}

export function isNumeric(number) {
  return validator.isNumeric(number + '');
}

export function isAlphanumeric(text) {
  return validator.isAlphanumeric(text + '');
}