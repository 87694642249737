import React, { useState } from "react";
import { unsubscribe } from '../../controllers/index'
import { Button, Card, Form, Input, Container, Row, Col, Spinner } from "reactstrap";
import { isEmail, isAlphanumeric } from "utils/validations";
import { toast } from 'react-toastify';
import ReactGA from 'react-ga';

function UnsubscribePage(props) {
  const pathEmail = props.match.params.email;
  const pathCode = props.match.params.code;

  const [email, setEmail] = useState(pathEmail || '');
  const [code, setCode] = useState(pathCode || '');
  const [loading, setLoading] = useState(false);
  const [forceDisabled, setForceDisabled] = useState(false);

  const onUnsubscribe = () => {
    setLoading(true);
    unsubscribe(email, code)
      .then(res => {
        setLoading(false);
        if (!res.error) {
          ReactGA.event({ category: 'Unsubcribe', action: 'Unsubscribe attempt', label: 'Unsubscribe attempt', value: 'Success' });
          setForceDisabled(true);
          toast.success('Unsubscribed succesfully');
        } 
        else {
          ReactGA.event({ category: 'Unsubcribe', action: 'Unsubscribe attempt', label: 'Unsubscribe attempt', value: 'Failure' });
          toast.error(res.error);
        } 
      })
  }

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("register-page");
    return function cleanup() {
      document.body.classList.remove("register-page");
    };
  });

  return (
    <>
      <div
        className="page-header fade-in"
        style={{
          backgroundImage: "url(" + require("assets/img/backgrounds/source.gif") + ")"
        }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="4">
              <Card className="card-register ml-auto mr-auto">
                <h3 className="title mx-auto">Unsubscribe</h3>
                <Form className="register-form">
                  <label>Email</label>
                  <Input
                    invalid={!isEmail(email)} disabled={Boolean(pathEmail)}
                    className='mb-4' placeholder="Email" type="text"
                    value={email} onChange={({ target: { value } }) => setEmail(value)}
                  />
                  <label>Subscription code</label>
                  <Input
                    className='mb-4' placeholder="Code" type="text" disabled={Boolean(pathCode)}
                    invalid={!(isAlphanumeric(code) && code.length > 10)}
                    value={code} onChange={({ target: { value } }) => setCode(value)}
                  />
                  <Button
                    block className="btn" outline={!(isEmail(email) && isAlphanumeric(code) && code.length > 10) || forceDisabled} color="danger"
                    onClick={onUnsubscribe} disabled={!(isEmail(email) && isAlphanumeric(code) && code.length > 10) || forceDisabled}
                  >
                    {
                      loading ?
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        :
                        'Unsubscribe'
                    }
                  </Button>
                </Form>
                <div className="forgot">
                  <Button
                    className="btn-link"
                    color="danger"
                    href="https://discord.gg/fKeTFjr"
                  >
                    Trouble? Get in touch!
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default UnsubscribePage;
