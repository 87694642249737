import React from 'react';
import '../styles.scss';
import DisplayPhotos from 'components/DisplayPhotos';
import HelpUs from './HelpUs';
import SubscribeForm from 'components/SubscribeForm';

const items = [
  {
    src: require("assets/img/products/cutekoala.jpg"),
  },
  {
    src: require("assets/img/products/forest-fire-at-night.jpg"),
  },
  {
    src: require("assets/img/products/burntkoala.jpg"),
  },
  {
    src: require("assets/img/products/cutekoala2.jpg"),
  },
  {
    src: require("assets/img/products/earthsaver-place.jpg"),
  },
  {
    src: require("assets/img/products/cutekoala3.jpg"),
  },
  {
    src: require("assets/img/products/canguro.jpg"),
  },
];

export default () => (
  <div className='story pt-5'>
    <div className='inverted-overlay' />
    <div className='row justify-content-center'>
      <div className='col-10 col-xl-7'>
        <h1 className='footer-title mt-0 mb-3'>Australia’s Koala population <strong>needs your help.</strong></h1>
        <DisplayPhotos items={items} />

        <h2 className="story-text mt-4">
          Donate to help restore and protect <strong>Koala habitat, by regenerating and protecting forests.</strong> Your donation will also help <strong>train land managers</strong> with Indigenous Australian cool burn techniques to preserve existing forests from catastrophic fire, and increase carbon stored in trees and plants.
        </h2>
        <h2 className="story-text mt-3">
          Next time fire comes through, there is a much higher chance of the forest canopy being protected, and Koalas surviving fires. Cool burning reduces fire loads and the risk of local catastrophic fire.
        </h2>

        <h2 className="story-text mt-3">
          The people and organisations who support us are called ‘Earth Savers’. Signing up for a <strong>monthly donation</strong> with Earth Saver, enables the organisations and projects we work with, to regenerate habitat for Koalas and <strong>make a real impact on an ongoing basis.</strong> 
        </h2>

        <h2 className="story-text">
          Climate change and extreme hot weather events are the new normal and we need to be able to restore ecosystems as fast as they are burnt.
          We plant trees, protect habitat, train land managers in Indigenous cool burning techniques, and create habitat corridors for wildlife. Habitat corridors create wild sanctuaries whilst connecting fragmented habitats for animals.
        </h2>
        <h2 className="story-text mt-3 mb-4">
          The trees we plant can sequester anywhere from 0.5 tonne to 15 tons of carbon over their lifetime.
        </h2>

        <iframe style={{ width: '100%', maxHeight: 700, minHeight: 300 }} 
          src="https://www.youtube.com/embed/Yu5GHhJrbHM" frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen title={'Earth Saver Video'}
        />
        <h2 className='story-text bold mt-4'>
          How do I join the Earth Saver Campaign, to regenerate and protect Koala Habitat?
        </h2>
        <h2 className='story-text mt-1'>
          You can <strong>become an Earth Saver with a monthly or one time donation</strong> for our projects and partners with long track records. Our most popular donation amount is $15 per month, to <strong>restore and protect essential habitat.</strong> 
          <br />
          Our land managers care for the trees as they grow and manage the habitat for wildlife. 
        </h2>
        <h2 className='story-text mt-3'> 
          As an Earth Saver donor, <strong>you will get an annual report</strong> of where the trees are located, and an estimate of how many Koalas and Kangaroos are in the habitat zone. We’ll keep reviewing where the money is most needed as the projects and the fire situation develops.
        </h2>
        <div className='mt-5 pt-5'>
          <HelpUs />
          <p className='story-text text-center mt-2'>and <strong>subscribe</strong> to our newsletter</p>
          <SubscribeForm/>
        </div>
      </div>
    </div>
  </div>
)