import React from "react";
import { Input, Button, Spinner } from "reactstrap";
import { isEmail } from '../utils/validations';
import { subscribeMailing } from '../controllers';
import { toast } from "react-toastify";
import ReactGA from 'react-ga';
import '../views/styles.scss';

function SubscribeForm({ black }) {
  const [email, onEmail] = React.useState('');
  const [isSubscribing, setSubscribe] = React.useState(false);

  const onSubscribe = () => {
    setSubscribe(true);
    subscribeMailing(email)
      .then(res => {
        setSubscribe(false);
        if (res.error) toast.error(toast.error);
        else toast.success('Subscribed!');
      })
  }

  return (
    <div className='row justify-content-center mt-2'>
      <div className='col-12 col-md-7 col-xl-7' style={{ textAlign: 'center' }}>
        <Input
          value={email} onChange={({ target: { value } }) => onEmail(value)}
          placeholder='your@email.com'
          invalid={Boolean(email && !isEmail(email))}
        />
        <Button outline={!isEmail(email)} onClick={onSubscribe}
          disabled={!isEmail(email)} style={{ width: '100%' }}
          className="btn button-enphasis mt-2"
          color={black ? undefined : 'neutral'}
        >
          {
            isSubscribing ?
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              :
              'Subscribe'
          }
        </Button>
      </div>
    </div >
  );
}

export default SubscribeForm;


