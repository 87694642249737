import React from 'react';
import {  Carousel, CarouselItem, CarouselIndicators, Card } from 'reactstrap';

export default ({ items }) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = (force) => {
    if (animating || (items[activeIndex].video && !force)) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  
  return (
    <Card className="page-carousel">
      <Carousel
        autoPlay={true}
        interval={2500}
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {items.map(item => {
          return (
            <CarouselItem
              onExiting={onExiting}
              onExited={onExited}
              key={item.src}
            >
              {
                <img src={item.src} alt={item.altText} />
              }
            </CarouselItem>
          );
        })}
        <a
          className="left carousel-control carousel-control-prev"
          data-slide="prev"
          href="/"
          onClick={e => {
            e.preventDefault();
            previous();
          }}
          role="button"
        >
          <span className="fa fa-angle-left" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="right carousel-control carousel-control-next"
          data-slide="next"
          href="/"
          onClick={e => {
            e.preventDefault();
            next(true);
          }}
          role="button"
        >
          <span className="fa fa-angle-right" />
          <span className="sr-only">Next</span>
        </a>
      </Carousel>
    </Card>
  )
}