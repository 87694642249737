import React from 'react';
import '../styles.scss';
import { Parallax } from 'react-parallax';

export default () => (
  <Parallax
    blur={1}
    bgImage={require('assets/img/products/forest-fire-at-night.jpg')}
    bgImageAlt="header"
    strength={200}
  >
    <div className='story pt-5'>
      <div className='inverted-overlay' />
      <div className='row justify-content-center'>
        <div className='col-12 col-xl-12'>
          <h1 className='footer-title mt-0 mb-3'><strong>Where</strong> we work</h1>
          <img alt='map' className='map'  src={require('assets/img/products/Earth-Saver-Map.png')}/>
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='col-10 col-xl-7'>
          <h2 className="story-text text-center mt-5">
            We work in Australia, India, Africa and South East Asia and soon USA and South America.
          </h2>
          <h2 className="story-text text-center mt-3">
            The Earth Saver model is more scalable than buying individial plots of land to save forests, because we pay organisations and individuals based on constantly updated satellite imagery.  
          </h2>
          <h2 className="story-text text-center mt-3">
            Carbon payments are based on proof living trees, that go directly to forest stewards and managers.
          </h2>
        </div>
      </div>
    </div>
  </Parallax>
)