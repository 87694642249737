import React, { useState } from 'react';
import CreditCardInput from 'react-credit-card-input';
import { Input, Button, Card, Spinner, Label } from 'reactstrap';
import contryNames from '../../utils/countries';
import { subscribe, donate } from '../../controllers';
import { isValidExpiration, isValidCvc, isCreditCard, isEmail, isNumeric } from 'utils/validations';
import { toast } from 'react-toastify';
import ShareBatch from "components/ShareBatch";
import ReactGA from 'react-ga';

const donationOptions = [5, 10, 15, 20, 25, 50, 75, 100, 150, 200, 250, 500, 1000, 2500, 5000, 10000];

export default ({ history }) => {
  const [donationType, setDonationType] = useState('subscription')

  const [cardholderName, onCardholderName] = useState('');
  const [cardNumber, onCardNumberChange] = useState('');
  const [cardExpiry, onExpiryNumberChange] = useState('');
  const [cardCvc, onCvcNumber] = useState('');
  const [zip, onZip] = useState('');
  const [country, onCountry] = useState('');

  const [flatAmount, onChangeFlatAmount] = useState('');
  const [type, onTypeChange] = useState(2);
  
  const [name, onName] = useState('');
  const [comment, onComment] = useState('');
  
  const [email, onEmail] = useState('');
  const [confirmEmail, onConfirmEmail] = useState('');

  const [isDonating, onDonating] = useState(false);

  const onDonate = () => {
    const billing = { cardholderName, cardNumber, cardExpiry, cardCvc, zip, country };

    onDonating(true);
    if (donationType === 'subscription') {
      subscribe(type, name, email, billing, comment)
        .then(res => {
          onDonating(false);
          if (res.error) {
            toast.error(res.error);
            ReactGA.event({ category: 'Donations', action: 'Subscribe attempt', label: 'Subscribe Attempt', value: 'Failure' });
          }
          else {
            ReactGA.event({ category: 'Donations', action: 'Subscribe attempt', label: 'Subscribe Attempt', value: 'Success' });
            toast.success('Donation successful!');
            history.push('/wall', { subscription: true });
          }
        });
    } else {
      donate(flatAmount, name, email, billing, comment)
        .then(res => {
          onDonating(false);
          if (res.error) {
            toast.error(res.error);
            ReactGA.event({ category: 'Donations', action: 'Donate attempt', label: 'Donate Attempt', value: 'Failure' });
          }
          else {
            ReactGA.event({ category: 'Donations', action: 'Donate attempt', label: 'Donate Attempt', value: 'Success' });
            toast.success('Donation successful!');
            history.push('/wall', { code: res });
          }
        });
    }
  }

  const isButtonDisabled = (!isEmail(email) || email !== confirmEmail) || !name || !isCreditCard(cardNumber) ||
    !isValidCvc(cardCvc) || !isValidExpiration(cardExpiry) || !country || !zip || !cardholderName
    || ((donationType === 'subscription' && !isNumeric(type)) || (donationType === 'flatDonation' && (!isNumeric(flatAmount) || !(Number(flatAmount) >= 1))));

  let contributingText;
  if (donationType === 'subscription') contributingText = `You are contributing ${donationOptions[type] || 0} USD monthly.`
  else contributingText = `You are contributing ${Number(flatAmount)} USD.`
  return (
    <form id='form-checkout'>
      <div className='row justify-content-center'>
        <div className='col-11 col-xl-7'>
          <Card className='checkout-card'>
            <div className='row'>
              <div className='col'>
                <label className='checkout-item'>
                  Donation in the name of
                  <Input
                    value={name} onChange={({ target: { value } }) => onName(value)}
                    placeholder='Name'
                  />
                </label>
              </div>
              <div className='col'>
                <label className='checkout-item'>
                  Referral Code
                  <Input
                    placeholder='#HFG40ERF'
                    disabled={true}
                  />
                </label>
              </div>
            </div>
            <div className='row'>
              <div className='col-6'>
                <label>
                  Contribution type
                </label>
                <div className='row'>
                  <div className='col'>
                    <div className="form-check-radio">
                      <Label check>
                        <Input
                          onChange={() => setDonationType('subscription')}
                          checked={donationType === 'subscription'}
                          type="radio"
                        />
                        Monthly <span className="form-check-sign" />
                      </Label>
                    </div>
                  </div>
                  <div className='col'>
                    <div className="form-check-radio">
                      <Label check>
                        <Input
                          onChange={() => setDonationType('flatDonation')}
                          checked={donationType === 'flatDonation'}
                          type="radio"
                        />
                        Once <span className="form-check-sign" />
                      </Label>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-6'>
                {
                  donationType === 'subscription' ?
                    <label className='checkout-item'>
                      Monthly donation (unsubscribe easily)
                      <select className='form-control w-100 sub-select' value={type} onChange={({ target: { value } }) => onTypeChange(value)}>
                        {
                          donationOptions.map((amount, idx) => (
                            <option key={idx} value={idx}>{amount} USD a month</option>
                          ))
                        }
                      </select>
                    </label>
                    :
                    <label className='checkout-item'>
                      Flat donation
                      <div className='donate-input'>
                        <Input
                          className="form-control donate-input"
                          placeholder="Donation"
                          value={flatAmount} onChange={({ target: { value } }) => onChangeFlatAmount(value)}
                          type="number"
                        />
                      </div>
                    </label>
                }
              </div>
            </div>
            <div className='separator' />
            <div className='row'>
              <div className='col-12 col-md-6'>
                <label className='checkout-item'>
                  Cardholder Name
                  <Input
                    value={cardholderName} onChange={({ target: { value } }) => onCardholderName(value)}
                    placeholder='Your Name'
                  />
                </label>
              </div>
              <div className='col-12 col-md-6'>
                <label className='checkout-item'>
                  Card details
                  <CreditCardInput
                    containerClassName='card-input'
                    cardImageClassName='card-image'
                    cardNumberInputProps={{ value: cardNumber, onChange: ({ target: { value } }) => onCardNumberChange(value) }}
                    cardExpiryInputProps={{ value: cardExpiry, onChange: ({ target: { value } }) => onExpiryNumberChange(value) }}
                    cardCVCInputProps={{ value: cardCvc, onChange: ({ target: { value } }) => onCvcNumber(value) }}
                    fieldClassName="input"
                  />
                </label>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <label className='checkout-item'>
                  Billing ZIP code
                  <Input
                    value={zip} onChange={({ target: { value } }) => onZip(value)}
                    placeholder='ZIP code'
                  />
                </label>
              </div>
              <div className='col-12 col-md-6'>
                <label className='checkout-item'>
                  Country
                  <select className='form-control w-100' value={country} onChange={({ target: { value } }) => onCountry(value)}>
                    <option value={''}>Choose a country</option>)}
                    {contryNames.map(({name, code}) => <option key={code} value={code}>{name}</option>)}
                  </select>
                </label>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 col-md-6'>
                <label className='checkout-item'>
                  Email
                  <Input
                    value={email} onChange={({ target: { value } }) => onEmail(value)}
                    placeholder='your@email.com'
                    invalid={Boolean(email && !isEmail(email))}
                  />
                </label>
              </div>
              <div className='col-12 col-md-6'>
                <label className='checkout-item'>
                  Confirm Email
                  <Input
                    value={confirmEmail} onChange={({ target: { value } }) => onConfirmEmail(value)}
                    placeholder='your@email.com'
                    invalid={Boolean(email && confirmEmail && confirmEmail !== email)}
                  />
                </label>
              </div>
            </div>
            <label className='mb-2'>
              Comment (optional)
              <Input
                value={comment} onChange={({ target: { value } }) => onComment(value)}
                placeholder='Leave a comment...'
              />
            </label>
            <Button outline={isButtonDisabled} color='primary' onClick={onDonate}
              className={isButtonDisabled ? 'disabled-button' : undefined}
              disabled={isButtonDisabled}
            >
              {
                isDonating ?
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  :
                  'Donate'
              }
            </Button>
            <a
              href='/tac'
              target="_blank"
              style={{ textDecoration: 'underline', textAlign: 'center' }}
            >
              By donating you accept our terms and conditions
            </a>
            <p className='text-center bold'>{contributingText}</p>
            <ShareBatch />
          </Card>
        </div>
      </div>
    </form>
  );
};