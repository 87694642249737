import axios from 'axios';
import HTTPError from '../utils/HTTPError';

const apiUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:9000' : 'https://api.saver.earthbanc.io'

export async function donate(amount, fullName, email, billing, comment) {
  const url = apiUrl + '/donation';
  const body = {
    email,
    amount,
    fullName,
    comment,
    ...billing
  };
  try {
    const response = await axios.post(url, body);
    return response.data.donationCode;
  } catch ({ response }) {
    if (response && response.data.error) return { error: HTTPError(response.data.error) }
    return { error: HTTPError() }
  }
}

export async function subscribe(type, fullName, email, billing, comment) {
  const url = apiUrl + '/subscription';
  const body = {
    fullName,
    comment,
    email,
    type,
    ...billing
  };
  try {
    await axios.post(url, body);
    return true;
  } catch ({ response }) {
    if (response && response.data.error) return { error: HTTPError(response.data.error) }
    return { error: HTTPError() }
  }
}

export async function unsubscribe(email, code) {
  const url = apiUrl + `/subscription/${email}/${code}`;
  try {
    await axios.delete(url);
    return true;
  } catch ({ response }) {
    if (response && response.data.error) return { error: HTTPError(response.data.error) }
    return { error: HTTPError() }
  }
}

export async function requestPrivacy(email) {
  const url = apiUrl + `/request/privacy/${email}`;
  try {
    await axios.post(url);
    return true;
  } catch ({ response }) {
    if (response && response.data.error) return { error: HTTPError(response.data.error) }
    return { error: HTTPError() }
  }
}

export async function subscribeMailing(email) {
  const url = apiUrl + `/newsletter/${email}`;
  try {
    await axios.post(url);
    return true;
  } catch ({ response }) {
    if (response && response.data.error) return { error: HTTPError(response.data.error) }
    return { error: HTTPError() }
  }
}
