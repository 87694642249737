import React from 'react';
import '../styles.scss';

export default () => (
  <div className='story pt-2'>
    <div className='inverted-overlay' />
    <div className='row justify-content-center'>
      <div className='col-10 col-xl-7'>
        <h1 className='footer-title mt-0 mb-3'>Know <strong>more</strong></h1>
        <h2 className="story-text">
          In 2019, over 17 millions acres of forest in Australia have burned, destroying habitats of billions of animals, and pushing Koalas and other animals closer to extinction. We need a strategy to restore the habitat, before it’s too late. 41 out of a total of 128 know habitats, already have local extinction. In contrast the Amazon has 2.24 million acres burnt, and we are only just beginning to see the true depth of the fire risk.
         </h2>
         <h2 className="story-text mt-3">
          Please join our platform to help regenerate and protect Koala habitat, and bring cool burn techniques of Indigenous custodians to all of Australia.
        </h2>
        <h2 className='story-text bold mt-5'>
          How do we support Koala Habitat?
        </h2>
        <h2 className='story-text mt-1'>
          We plant indigenous trees that Koala's use as habitat trees. Koala's need a large area of habitat to not only survive, but thrive. Koala’s can only eat particular tree leaves of various eucalypt sub-species, and they require those trees to be densely packed into a geographic range in which they also mate.
        </h2>
        <h2 className='story-text mt-3'>
          By working with private land holders to regenerate and create new habitat corridors, we help private land holders create wildlife sanctuaries by connecting fragmented habitats for animals.  
        </h2>
        <h2 className='story-text mt-3'>
          We protect the trees from deforestation with your support and actively regenerate forests at sites across Australia. Training land managers in permitted cool burning techniques for cool and wet weather months, reduces the chances of catastrophic fire which killed over a billion animals in Australia.
        </h2>
        <h2 className='story-text mt-1'>
          We have programs in other countries around the world, <strong>please talk to us</strong> if you are interested in becoming a partner with Earth Saver.
        </h2>
        <h2 className='story-text bold mt-5'>
          How are we making a climate impact with the monthly donation or one time donation?
        </h2>
        <h2 className='story-text mt-1'>
          Your monthly donation is supporting organisations that take care of Koala habitat, restoring and protecting their ecosystems. The fires have burned so much land, we need to restore Koala food trees so that they have enough food supply. 
        </h2>
        <h2 className='story-text mt-3'>
          Our projects plant new trees and also protect existing forest habitat used by Koalas.
          Trees and plants absorb carbon gas (CO2) from the atmosphere and turn it into oxygen. Reforestation and protection of existing forests, play an important role in maintaining a healthy climate and providing crucial habitat for wildlife. Forests sequester large amounts of carbon, including the Earth Saver forests that your monthly donation supports. Some of our sites contain trees that are hundreds of years old, some have trees we planted 20 years ago, while others are growing completely new trees. As carbon gets absorbed into trees and soil, we estimate your carbon offset potential.
        </h2>
        <h2 className='story-text mt-3'>  
          We work with species that maximize the local conditions and absorption of CO2. Different species of trees absorb varying amounts of carbon dioxide. Some eucalyptus tree species (Koalas eat only Eucalyptus tree leaves) can sequester 0.5 - 15 tonnes of carbon over their lifetime. Some trees can live a hundred years or more. Every $15 donated is enabling between 0.5 - 15 tonnes of carbon sequestration over the tree’s lifetime, depending on tree sub-species and location.          
        </h2>
        <h2 className='story-text bold mt-5'>
          How do your projects operate and how do you work with communities? 
        </h2>
        <h2 className='story-text mt-1'>
          We develop some projects ourselves from the ground up, and we also work with communities and organisations who partner with Indigenous land management experts, to restore habitat for wildlife which they project manage. Our network provides education and training on ecosystem restoration, regenerative agriculture which can incorporate many indigenous species, permaculture, sustainable land and water management. 
        </h2>
        <h2 className='story-text bold mt-5'>
          What type of trees do we work with?
        </h2>
        <h2 className='story-text mt-1'>
          Indigenous and food trees suitable to local ecosystem and cultural conditions.
        </h2>
        <h2 className='story-text mt-3 mb-5'>
          From 2000 onwards our network have planted millions of trees across south east Australia, and some of our own ecological regeneration sites have 50,000 trees per habitat area.
        </h2>
      </div>
    </div>
  </div>
)