/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";
import { Tick } from 'react-crude-animated-tick';
import ShareBatch from "components/ShareBatch";

function ProfilePage({ history, location }) {
  const isSubscription = (location.state && location.state.subscription);
  const thereIsCode = (location.state && location.state.code);
  const shouldRedirect = !thereIsCode && !isSubscription;
  shouldRedirect && history.push('/');

  return (
    <>
      <div
        className="page-header"
        style={{
          backgroundImage: "url(" + require("assets/img/login-image1.jpg") + ")"
        }}
      >
        <div className="filter" />
        <div style={{ paddingTop: 150, zIndex: 1 }}>
          <Container>
            <h1 className="banner-title text-center mt-5">Thank you!</h1>
            <h1 className="banner-subtitle text-center mb-3"><strong>But don't leave yet!</strong></h1>
            <div className="owner">
              <Tick size={200} />
              <div className="name">
                {
                  (!shouldRedirect && !isSubscription) &&
                  <h4 className="title">
                    #{!shouldRedirect && location.state.code}
                  </h4>
                }
                <h6 className="description just-white">Your donation may bring you benefits</h6>
              </div>
            </div>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <p className='white bold'>
                  We sent you a code to your email so you can claim your earth rewards points in the future.
                </p>
                <p className='white bold'>
                  If you don't receive any emails from us, do not worry. You may be able to still claim your points with your credit/debit card details at a later stage.
                </p>
                <p className='white bold'>
                  If you claim them, you may be eligible for discounts with participating retailers.
              </p>
              </Col>
            </Row>
            <ShareBatch white />
          </Container>
        </div>
      </div>
    </>
  );
}

export default ProfilePage;
