import React from "react";
import LandingBanner from "./LandingBanner";
import '../styles.scss';
import SomeStory from "./SomeStory";
import OurNetworks from './OurNetworks';
import Team from "./Team";
import OurProjects from "./OurProjects";
import WhatWeDo from './WhatWeDo';
import KnowMore from "./KnowMore";
import MovingFog from "./MovingFog";
import Checkout from "views/Contribute/Checkout";

function Landing({ history }) {
  return (
    <div className='landing-page'>
      <LandingBanner />
      <MovingFog />
      <OurNetworks />
      <WhatWeDo />
      <MovingFog />
      <OurProjects />
      <SomeStory />
      <KnowMore />
      <MovingFog />
      <Checkout history={history}/>
      <Team />
    </div>
  );
}

export default Landing;
