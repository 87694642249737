const errors = {
  EXISTING_SUBSCRIPTION: "The provided email has already subscribed, please use another one.",
  ERROR_WHILE_REGISTERING: "Your donation was successfully acredited but we had trouble registering it. Contact support if you want us to register it for future benefits.",
  ERROR_WHILE_EMAIL: "Your donation was successfully acredited but we had trouble sending an email to you. Contact support if you want us to register it for future benefits.",
  ERROR_WHILE_PAYING: "Error while issuing the donation, please check your card details.",
  INVALID_DONATION_VALUE: "The minimum donation we can accept is 1 USD.",
  MISSING_PRIVACY_INFO: "We don't have any information linked to this email.",
  INVALID_PERSONAL_INFO: "Invalid personal details, please check you are not using symbols in your name",
  UNMATCHING_UNSUBSCRIBE_INFO: "No active subscriptions found with that combination",
  ERROR_WITH_INFO: "We had trouble with your personal information, please check it has no symbols.",
  UNSUBSCRIBING_ERROR: "Error while unsubscribing, please contact support."
}

export default errorCode => {
  return errors[errorCode] || 'Error while processing request, please try again later.';
}