import React from 'react';
import { Row, Container } from "reactstrap";
import SubscribeForm from 'components/SubscribeForm';

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <div className='col-6 col-md-4' align='center'>
            <a className='footer-item'
              href="https://earthbanc.io"
              target="_blank" rel='noopener noreferrer'
            >
              By Earthbanc
            </a>
          </div>
          <div className='col-6 col-md-4' align='center'>
            <a className='footer-item'
              href="/tac"
              target="_blank"
            >
              Terms&Conditions
            </a>
          </div>
          <div className='col-12 col-md-4' align='center'>
            <a className='footer-item'
              href="/privacy"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
          <div className='col-12'>
            <SubscribeForm black/>
          </div>
          <div className='col-12' align='center'>
            <div className="credits ml-auto">
              <span className="copyright">
                © {new Date().getFullYear()}
                <i className="fa fa-heart heart" /> by Earthbanc
              </span>
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
