import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// pages
import Landing from 'views/Landing/Landing';
import Wall from 'views/Wall/Wall';
import Unsubscribe from 'views/Contribute/Unsubscribe';
import Navbar from 'views/Navbar';
import TAC from 'views/TAC';
import Privacy from 'views/Privacy'
import Widget from 'views/Widget';
import Footer from 'views/Landing/Footer';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
// others

ReactGA.initialize("UA-156540757-1");
const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

class App extends React.Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  render() {
    return (
      <>
        {history.location.pathname !== '/widget' && <Navbar />}
        <Router history={history}>
          <Switch>
            <Route exact path="/" render={props => <Landing {...props} />} />
            <Route path="/unsubscribe/:email?/:code?" render={props => <Unsubscribe {...props} />} />
            <Route path='/wall' render={props => <Wall {...props} />} />
            <Route path='/tac' render={props => <TAC {...props} />} />
            <Route path='/privacy' render={props => <Privacy {...props} />} />
            <Route path='/widget' render={props => <Widget {...props} />} />
            <Redirect to="/" />
          </Switch>
          <ToastContainer position='bottom-center' />
        </Router>
        {history.location.pathname !== '/widget' && <Footer />}
      </>
    );
  }
}



const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
