import React from 'react';
import { Row, Card, CardBody, Col, CardTitle } from "reactstrap";
import '../styles.scss';

export default () => (

  <div className='team-container'>
    <Row className='justify-content-center'>
      <div className='col-12 col-xl-7'>
        <div className='mb-4 mt-4'>
          <div style={{ textAlign: 'center' }}>
            <a
              rel='noopener noreferrer'
              href="https://discord.gg/fKeTFjr"
              target="_blank"
            >
              <p className='footer-title'>
                Talk to us
              </p>
            </a>
          </div>
          <div className="title-brand font-adjusted">
            <h1 className="presentation-title smaller little">Organizers</h1>
          </div>
          <Row className='mt-5'>
            <Col md="4">
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="/" onClick={e => e.preventDefault()}>
                    <img
                      alt="..."
                      src={require("assets/img/team/tom.jpg")}
                    />
                  </a>
                </div>
                <CardBody>
                  <a href="/" onClick={e => e.preventDefault()}>
                    <div className="author">
                      <CardTitle className='just-white' tag="h4">Tom Duncan</CardTitle>
                      <h6 className="just-white card-category">{'Co-Founder & CEO'}</h6>
                    </div>
                  </a>
                  <p className="card-description  just-white text-center">
                    Nintendo, HP, Liquid Token Founder, CIO Family Office, Investment Banking, RMIT, Research Fellow CRDC George Mason University, Centre for Sustainability Leadership Alumni, Author Environmental Science, Inventor AquaBiofilter
                </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="/" onClick={e => e.preventDefault()}>
                    <img
                      alt="..."
                      src={require("assets/img/team/chau.jpg")}
                    />
                  </a>
                </div>
                <CardBody>
                  <a href="/" onClick={e => e.preventDefault()}>
                    <div className="author">
                      <CardTitle className='just-white' tag="h4">Chau Tang</CardTitle>
                      <h6 className="just-white card-category">{'Co-Founder & COO'}</h6>
                    </div>
                  </a>
                  <p className="card-description  just-white text-center just-white">
                    {'Commonwealth Bank, Director of Investment UK Trade & Investment, NAB, ANZ, BankWest, AusTrade - Trade Commissioner Clean Energy & Environment, MSci Environment, Bachelor Banking & Finance'}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="/" onClick={e => e.preventDefault()}>
                    <img
                      alt="..."
                      src={require("assets/img/team/franco.jpg")}
                    />
                  </a>
                </div>
                <CardBody>
                  <a href="/" onClick={e => e.preventDefault()}>
                    <div className="author">
                      <CardTitle className='just-white' tag="h4">Franco Scucchiero</CardTitle>
                      <h6 className="just-white card-category">{'Co-Founder & Lead Developer'}</h6>
                    </div>
                  </a>
                  <p className="card-description  just-white text-center">
                    {'CoinFabrik Project Dev - Algorand, Xapo Exchange, MyCrypto Wallet, aeternity, Ledger Hardware Wallet, Terrae MMORG, Physics and Hardware enthusiast'}
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col md="4">
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="/" onClick={e => e.preventDefault()}>
                    <img
                      alt="..."
                      src={require("assets/img/team/alan.jpg")}
                    />
                  </a>
                </div>
                <CardBody>
                  <a href="/" onClick={e => e.preventDefault()}>
                    <div className="author">
                      <CardTitle className='just-white' tag="h4">Alan Channer PhD</CardTitle>
                      <h6 className="just-white card-category">{'Ecology Metrics & Project Manager'}</h6>
                    </div>
                  </a>
                  <p className="card-description  just-white text-center">
                    Global EverGreening Alliance, ICRAF, Caux Forum, University of Reading PhD Ecology, Intercultural Innovation Award, 2017 @UN Alliance of Civilisations
                        </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="/" onClick={e => e.preventDefault()}>
                    <img
                      alt="..."
                      src={require("assets/img/team/rishab.jpg")}
                    />
                  </a>
                </div>
                <CardBody>
                  <a href="/" onClick={e => e.preventDefault()}>
                    <div className="author">
                      <CardTitle className='just-white' tag="h4">Rishabh Khanna</CardTitle>
                      <h6 className="just-white card-category">{'Co-Founder & Chief Impact Officer'}</h6>
                    </div>
                  </a>
                  <p className="card-description  just-white text-center just-white">
                    {'Founder, EnviroHealth Matters, Research Analyst - Research Foundation for Science Technology & Ecology, Climate Solutions, Indian Youth Climate Network, IofC'}
                  </p>
                </CardBody>
              </Card>
            </Col>
            <Col md="4">
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="/" onClick={e => e.preventDefault()}>
                    <img
                      alt="..." style={{ objectFit: 'cover' }}
                      src={require("assets/img/team/peggy.jpg")}
                    />
                  </a>
                </div>
                <CardBody>
                  <a href="/" onClick={e => e.preventDefault()}>
                    <div className="author">
                      <CardTitle className='just-white' tag="h4">Peggy Liu</CardTitle>
                      <h6 className="just-white card-category">{'Advisor - Strategy'}</h6>
                    </div>
                  </a>
                  <p className="card-description  just-white text-center">
                    {'Drawdown Board Member, EAT Forum Advisor, Food Heroes, Founder & Chair at Joint US-China Collaboration on Clean Energy, McKinsey & Company, Symantec, HP, Marks & Spencer, CGI, MIT, Harvard, EHF'}
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className='justify-content-center'>
            <Col md='4'>
              <Card className="card-profile card-plain">
                <div className="card-avatar">
                  <a href="/" onClick={e => e.preventDefault()}>
                    <img
                      alt="..." style={{ objectFit: 'cover' }}
                      src={require("assets/img/team/helgeson.jpg")}
                    />
                  </a>
                </div>
                <CardBody>
                  <a href="/" onClick={e => e.preventDefault()}>
                    <div className="author">
                      <CardTitle className='just-white' tag="h4">Jennifer Helgeson</CardTitle>
                      <h6 className="just-white card-category">{'Chief Climate Economist'}</h6>
                    </div>
                  </a>
                  <p className="card-description  just-white text-center">
                    {'Environmental Valuation, Community Resilience Planning, LSE PhD Geography, Univ of Oxford MSc Environmental Change & Management, Fulbright Awardee, Climate Adaptation Economics, Grantham Institute, OECD, Microinsurance'}
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </Row>
  </div>
)