import React from 'react';
import { Row } from 'reactstrap';
import HelpUs from './HelpUs';
import '../styles.scss';

export default () => (
  <div className='page-background'>
    <div className='banner-overlay' />
    <div className='page-banner'>
      <Row className='justify-content-center mt-5'>
        <div className='col-10 col-xl-7 text-center'>
          <h1 className="banner-title bold">Become an Earth Saver</h1>
          <h1 className='banner-subtitle'>Donate monthly, to help save koalas and grow forests</h1>
          <HelpUs />
          <div className='mt-4'>
            <p className="banner-description"> We plant trees, protect habitat corridors & train land managers in Indigenous cool burning techniques to reduce fire risk.</p>
          </div>
        </div>
      </Row>
      {/* <div className='banner-know'>
        <a href='#second'>
          <p className='more-button'> Know More </p>
        </a>
      </div> */}
    </div>
  </div>
)