import React from 'react';
import { Button } from 'reactstrap';

export default () => (
  <div className='row justify-content-center mt-5'>
    <div className='col-12 col-md-7 col-xl-7' style={{ textAlign: 'center' }}>
      <Button
        outline
        className="btn button-enphasis"
        color="neutral"
        style={{ width: '100%' }}
        onClick={() => {
          const element = document.getElementById('form-checkout')
          const offset = element && element.offsetTop ? element.offsetTop : 0;
          window.scrollTo({ top: offset, behavior: 'smooth' })
        }}
      >
        Donate now
      </Button>
    </div>
  </div >
)