import React from "react";
import '../styles.scss';

function OurNetworks() {
  return (
    <div className='our-networks'>
      <h1 className='footer-title mt-0 mb-3 pt-5 text-center fade-in'>We're proud to have the support of <br/> <strong>Jane Goodall OBE</strong></h1>
      <div className='jane-godall'>
        <div className='testimonial-caption'>
          <p className='testimonial-text'><strong className='quotation'>“</strong> <strong>I am delighted to add my support to Earthbanc</strong> & EcoFriend in their quest to restore mangroves and cloud forests, conserve wildlife, develop regenerative agriculture and bring green investment to nurture these activities. <strong className='quotation'>”</strong></p>
        </div>
      </div>
      <div className='the-crossing-container'>
        <div className='the-crossing'/>
        <div className='testimonial-caption'>
          <p className='testimonial-name black bold'>The Crossing</p>
          <p className='testimonial-text black'><strong className='quotation'>“</strong> <strong>The Crossing Land Education Trust supports Earth Saver,</strong> and welcomes the opportunity to raise money for providing educational training in Indigenous cool burning techniques to reduce wildfire fuel loads, whilst also focusing our continued multi-decade efforts to restore and protect habitat for Koalas and wildlife that depend on habitat corridors for their survival. <strong className='quotation'>”</strong></p>
        </div>
      </div>
    </div>
  );
}

export default OurNetworks;
