import React from 'react';
import '../styles.scss';
import { Parallax } from 'react-parallax';
import HelpUs from './HelpUs';
import SubscribeForm from 'components/SubscribeForm';
export default () => (
  <Parallax
    blur={1}
    bgImage={require('assets/img/backgrounds/bushfire-brigade.jpg')}
    bgImageAlt="header"
    strength={200}
  >
    <div className='story pt-5'>
      <div className='inverted-overlay' />
      <div className='row justify-content-center'>
        <div className='col-10 col-xl-7'>
          <h1 className='footer-title mt-0 mb-3'><strong>What</strong> we do</h1>
          <div className='row justify-content-center'>
            <div className='col-12 col-xl-6' align='center'>
              <div className='avatar mt-5' >
                <img alt='item' className='avatar-image' src={require('assets/img/products/protect.png')} />
              </div>
              <h2 className="story-text bold">
                PROTECT
                </h2>
              <h2 className="story-text">
                Earth Saver protects wildlife habitat in forests and grasslands with our partners around the world.
                </h2>
            </div>
            <div className='col-12 col-xl-6' align='center'>
              <div className='avatar mt-5'>
                <img alt='item' className='avatar-image' src={require('assets/img/products/support.png')} />
              </div>
              <h2 className="story-text bold">
                SUPPORT
                </h2>
              <h2 className="story-text">
                We support organisations that identify Koala habitat areas, protecting and regenerating forest with land management including Indigeneous cool burning methods.
                </h2>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 col-xl-6' align='center'>
              <div className='avatar mt-5'>
                <img alt='item' className='avatar-image' src={require('assets/img/products/verify.png')} />
              </div>
              <h2 className="story-text bold">
                VERIFY
                </h2>
              <h2 className="story-text">
                We verify Koala and wildlife forest habitat with Satellite AI and our onground land management and training partners.
                </h2>
            </div>
            <div className='col-12 col-xl-6' align='center'>
              <div className='avatar mt-5'>
                <img alt='item' className='avatar-image' src={require('assets/img/products/care.png')} />
              </div>
              <h2 className="story-text bold">
                REGENERATE
                </h2>
              <h2 className="story-text">
                We regenerate forests and grasslands to restore wildlife habitat and draw down carbon. Planetary regeneration is our goal.
                </h2>
            </div>
          </div>
          <h2 className="story-text mt-5 text-center">
            Earthbanc uses cutting edge AI driven satellite imagery to constantly monitor forests to verify that wildlife and carbon is present.
          </h2>
          <h2 className="story-text mt-2 text-center">
            Your donation support Earth Savers in Australia who are taking care of forest and restoring Koala habitat.
          </h2>
          <h2 className="story-text mt-2 text-center">
            We also have projects in India, Africa and South East Asia.
            <br/>
            The Earth Saver model is more scalable than buying individual plots of land to save forests, because we pay organisations and individuals based on constantly updated satellite imagery. 
            <br/>
            Carbon payments are based on proof of living trees, that go directly to forest stewards and managers.
          </h2>
          <div className='mt-5 pt-5'>
            <HelpUs />
            <p className='story-text text-center mt-2'>and <strong>subscribe</strong> to our newsletter</p>
            <SubscribeForm/>
          </div>
        </div>
      </div>
    </div>
  </Parallax >
)